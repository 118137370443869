<template>
  <PageTemplate>
		<Section>
			<Card size="large">
				<CardSection>
					<Stack direction="column" space="3">
						<Heading size="1" align="center">our treatments.</Heading>
						<Heading size="4" align="center" color="tertiary" uppercase>Call Sedona to book your appointment today!</Heading>
						<Paragraph align="center">We're committed to skin and body enhancements that add to your natural beauty—and the youthful energy you feel inside.</Paragraph>
						<Button label="Contact Sedona" color="primary" url="/sedona/contact" />
					</Stack>
				</CardSection>
				<CardSection>
					<Stack direction="column" space="3" align="left">
						<Grid>
							<GridContainer size="12 6@md 4@lg">
								<Stack direction="column" space="3">
									<Images height="250px" width="100%" image="theme-sd/treatment3.jpg" />
									<Stack direction="column" space="1">
										<Heading size="4" color="tertiary">TREATMENTS</Heading>
										<Heading size="3">Oil Massage</Heading>
										<Paragraph align="center">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean egestas magna at porttitor vehicula nullam augue Lorem ipsum dolor sit.</Paragraph>
										<Button label="Read more" color="primary" size="small" url="/sedona/treatments/treatment" />
									</Stack>
								</Stack>
							</GridContainer>
							<GridContainer size="12 6@md 4@lg">
								<Stack direction="column" space="3">
									<Images height="250px" width="100%" image="theme-sd/treatment2.jpg" />
									<Stack direction="column" space="1">
										<Heading size="4" color="tertiary">TREATMENTS</Heading>
										<Heading size="3">Skin Scrub</Heading>
										<Paragraph align="center">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean egestas magna at porttitor vehicula nullam augue Lorem ipsum dolor sit.</Paragraph>
										<Button label="Read more" color="primary" size="small" url="/sedona/treatments/treatment" />
									</Stack>
								</Stack>
							</GridContainer>
							<GridContainer size="12 6@md 4@lg">
								<Stack direction="column" space="3">
									<Images height="250px" width="100%" image="theme-sd/treatment1.jpg" />
									<Stack direction="column" space="1">
										<Heading size="4" color="tertiary">TREATMENTS</Heading>
										<Heading size="3">Natural Exfoliating</Heading>
										<Paragraph align="center">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean egestas magna at porttitor vehicula nullam augue Lorem ipsum dolor sit.</Paragraph>
										<Button label="Read more" color="primary" size="small" url="/sedona/treatments/treatment" />
									</Stack>
								</Stack>
							</GridContainer>
							<GridContainer size="12 6@md 4@lg">
								<Stack direction="column" space="3">
									<Images height="250px" width="100%" image="theme-sd/treatment6.jpg" />
									<Stack direction="column" space="1">
										<Heading size="4" color="tertiary">TREATMENTS</Heading>
										<Heading size="3">Therapeutic Massage</Heading>
										<Paragraph align="center">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean egestas magna at porttitor vehicula nullam augue Lorem ipsum dolor sit.</Paragraph>
										<Button label="Read more" color="primary" size="small" url="/sedona/treatments/treatment" />
									</Stack>
								</Stack>
							</GridContainer>
							<GridContainer size="12 6@md 4@lg">
								<Stack direction="column" space="3">
									<Images height="250px" width="100%" image="theme-sd/treatment4.jpg" />
									<Stack direction="column" space="1">
										<Heading size="4" color="tertiary">TREATMENTS</Heading>
										<Heading size="3">Shoulder Massage</Heading>
										<Paragraph align="center">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean egestas magna at porttitor vehicula nullam augue Lorem ipsum dolor sit.</Paragraph>
										<Button label="Read more" color="primary" size="small" url="/sedona/treatments/treatment" />
									</Stack>
								</Stack>
							</GridContainer>
							<GridContainer size="12 6@md 4@lg">
								<Stack direction="column" space="3">
									<Images height="250px" width="100%" image="theme-sd/treatment5.jpg" />
									<Stack direction="column" space="1">
										<Heading size="4" color="tertiary">TREATMENTS</Heading>
										<Heading size="3">Revitalizing Cells</Heading>
										<Paragraph align="center">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean egestas magna at porttitor vehicula nullam augue Lorem ipsum dolor sit.</Paragraph>
										<Button label="Read more" color="primary" size="small" url="/sedona/treatments/treatment" />
									</Stack>
								</Stack>
							</GridContainer>
						</Grid>
					</Stack>
				</CardSection>
			</Card>
		</Section>
	</PageTemplate>
</template>

<script>
import PageTemplate from '@/views/projects/sedona/template/Page'; 
export default {
  components: {
		PageTemplate
  },
	props: {
		theme: String
	},
  mounted() {
    let bodyElement = document.documentElement;
    localStorage.setItem("theme", this.theme);
    bodyElement.setAttribute('theme', this.theme);
  },
}

</script>

<style lang="scss">
</style>
